<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <div class="source">
        {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
        <router-link :to="{ path: '/' }" > {{ $t('index.index') }} </router-link>
        &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;{{ $t('index.subsidy.personFundsDetail') }}
      </div>

      <div class="news">
        <div class="personInfo">
          <div class="news-bg">{{ $t('index.personInfo.info') }}</div>
          <div class="news">
            <FlatList
              :list="result"
              :height="name !== ' ' ? '500px' : '450px'"
              :showLoadSpin="showLoadSpin"
              :isRefresh="isRefresh"
              class="detail"
              :class="{ 'm-t-25': name !== ' ' }"
            >
              <div
                v-for="item in result"
                :key="item.idcard"
                style="border-bottom: 1px solid #ccc; padding: 5px"
              >
                <router-link
                  :to="{
                    name: 'funddetail',
                    params: { idcard: item.remark },
                  }"
                >
                  <h3>{{ item.name }}</h3>
                  <p>{{ $t('index.personInfo.idCard') }}：{{ item.idcard }}</p>
                </router-link>
              </div>
            </FlatList>
          </div>
          
          <!-- <div v-else style="padding: 2rem 0; text-align: center">
            <h3>{{ $t('index.subsidy.noData') }}</h3>
          </div> -->
        </div>
      </div>
      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "../utils/CryptoJS";
import BASE from "../utils/base";

import { Toast } from "vant";

export default {
  name: "fundPersonList",
  mounted() {
    // this.show()
    console.log("t>>>", this.$route);
  },
  activated() {
    // when the Vue app is booted up, this is run automatically.
    let idcard = this.$route.params.idcard || "";
    if (this.idcard !== idcard) {
      this.idcard = idcard
      this.result = []
      this.getPersonList(idcard)
    }
    
  },
  data(){
    return {
      result: [],
      idcard: ''
    }
  },
  methods: {
    getPersonList(name) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(BASE.genUrl("zxSuperviseMoney/onebyname", { name }))
        .then((res) => {
          Toast.clear();
          this.result = res.data.result.records
        }).catch(err => {
          Toast.clear();
        });
    }
  },
};
</script>
<style scoped>
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}

.personInfo {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.5rem;
}

.footer {
  padding: 0.5rem 0;
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.detail {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: left;
}

.personInfo span {
  color: #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.info-title {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

h3 {
  margin-bottom: 0.3rem;
}

.detail p {
  font-size: 14px;
  line-height: 25px;
}

.detail li {
  margin: 0 0.5rem;
  border-bottom: 1px solid #ccc;
}
</style>
